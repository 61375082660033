<template>
  <base-modal-brand :show="show"
                    :shake="error"
                    @close="$emit('close')">
    <auth-modal-login v-if="type === 'login'" @errorStateChange="toggleError"/>
    <auth-modal-register v-if="type === 'register'"/>
    <auth-modal-password-recovery v-if="type === 'passwordRecovery'"/>
    <auth-modal-register-success v-if="type === 'registerSuccess'"></auth-modal-register-success>

    <template slot="footer">
      <portal-target name="authModalFooter" slim/>
    </template>
  </base-modal-brand>
</template>

<script>
  import AuthModalLogin from './AuthModalLogin.vue'
  import AuthModalRegister from './AuthModalRegister.vue'
  import AuthModalPasswordRecovery from './AuthModalPasswordRecovery.vue'
  import AuthModalRegisterSuccess from './AuthModalRegisterSuccess.vue'

  export default {
    components: {
      AuthModalLogin,
      AuthModalRegister,
      AuthModalPasswordRecovery,
      AuthModalRegisterSuccess
    },

    props: {
      show: {
        required: true,
        type: Boolean
      },

      type: {
        required: false,
        type: String
      },

      user: {
        required: false,
        type: Object
      }
    },

    data() {
      return {
        error: false
      }
    },

    methods: {
      toggleError(errorState) {
        this.error = errorState
      }
    }
  }
</script>

<style lang="scss">
  // FIXME: there is a problem when there are more content
  // than it is now (content starts to get out of the screen)
  .base-modal-brand {
    overflow: auto;
  }
</style>
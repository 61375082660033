<template>
  <div class="user-section" ref="userSection">
    <span class="user-name">{{ $currentUser.full_name || 'Без имени' }}</span>
    <base-avatar :user="$currentUser" size="small" class="user-section-avatar"></base-avatar>

    <ul class="menu" ref="userDropdown">
      <li>
        <router-link class="sub-link"
                     v-if="!$currentUser.isAdmin"
                     :to="{ name: 'persons.profile', params: { id: currentUser.user_id } }">
          {{ $t('siteHeader.userSection.profile') }}
        </router-link>
      </li>
      <li>
        <router-link class="sub-link"
                     :to="{ name: 'profileSettings' }">
          {{ $t('siteHeader.userSection.settings') }}
        </router-link>
      </li>
      <li>
        <a href="#"
           class="sub-link"
           @click.prevent="logout">
          {{ $t('siteHeader.userSection.logout') }}
        </a>
      </li>
    </ul>
  </div>

</template>

<script>
import { logout } from '@/services/auth'

export default {
  mounted() {
    this.initUserDropdown()
  },

  methods: {
    logout() {
      logout()
      window.location.reload()
    },

    initUserDropdown() {
      tippy(this.$refs.userSection, {
        content: this.$refs.userDropdown,
        interactive: true,
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        inertia: true,
        duration: [600,300]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.user-section {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  .user-name {
    max-width: 150px;
    margin-right: 10px;
    font-size: 16px;
    color: $primary-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-section-avatar {
    flex-shrink: 0;
  }
}

</style>

<template></template>

<script>
  import survio from '@/services/survio'

  const minLooksNumberForSurvey = {
    generalSurvey: {
      client: 3,
      agent: 4
    }
  }

  export default {
    data() {
      return {
        userType: null
      }
    },

    methods: {
      setUserType() {
        const userTypes = this.constants$.userTypes
        const userTypesMap = {
          [userTypes.client]: 'client',
          [userTypes.agent]: 'agent',
          [userTypes.stylist]: 'stylist',
          [userTypes.admin]: 'admin'
        }

        this.userType = userTypesMap[this.$currentUser.user_type]
      },

      shouldOpenGeneralSurvey() {
        if (!this.userType || !minLooksNumberForSurvey.generalSurvey.hasOwnProperty(this.userType)) {
          return false
        }

        if (JSON.parse(localStorage.getItem('wasGeneralSurveyOpenedBefore'))) {
          return false
        }

        if (this.totalLooksNumber < minLooksNumberForSurvey.generalSurvey[this.userType]) {
          return false
        }

        return true
      },

      openGeneralSurvey() {
        survio[this.userType].openGeneralSurvey()
      }
    },

    computed: {
      ...mapGetters('looks', {
        totalLooksNumber: 'getTotalLooksNumber'
      })
    },

    watch: {
      $currentUser: {
        handler(val) {
          if (!val) {
            return
          }

          this.setUserType()
        },
        immediate: true
      },

      totalLooksNumber: {
        handler(val) {
          if (this.shouldOpenGeneralSurvey()) {
            this.openGeneralSurvey()
          }
        },
        immediate: true
      }
    }
  }
</script>

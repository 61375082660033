<template>
  <div class="height">
    <div class="title">Height</div>

    <template v-if="system === 'metric'">
      <input type="text"
             maxlength="3"
             v-model.number="centimeters">
    </template>

    <template v-if="system === 'imperial'">
      <div class="imperial">
        <input type="text"
               maxlength="3"
               v-model.number="feet">

        <span class="ft">ft</span>

        <input type="text"
               maxlength="3"
               v-model.number="inches">
      </div>
    </template>

    <select v-model="system">
      <option value="imperial">inch</option>
      <option value="metric">cm</option>
    </select>
  </div>
</template>

<script>
const inchToCm = 2.54
const footToCm = 30.48

export default {
  data () {
    return {
      system: 'imperial',
      centimeters: '',
      inches: '',
      feet: ''
    }
  },

  watch: {
    centimeters () {
      this.$emit('input', this.centimeters)
    },

    inches () {
      this.$emit('input', this.convertedValue)
    },

    feet () {
      this.$emit('input', this.convertedValue)
    },

    system () {
      this.centimeters = ''
      this.feet = ''
      this.inches = ''
    }
  },

  computed: {
    convertedValue () {
      return Number((this.feet * footToCm + this.inches * inchToCm).toFixed(0))
    }
  }
}
</script>

<style lang="scss" scoped>
.height {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 60px;
  border: 1px solid rgb(233, 233, 233);
  padding: 0px 15px;

  .title {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    color: rgb(208, 208, 208);
  }

  .imperial {
    display: flex;
    align-items: center;
  }

  .ft {
    color: #B6B6B6;
    font-size: 16px;
  }

  input {
    width: 46px;
    border: 0;
    border-bottom: 1px solid;
    outline: none;
    text-align: center;
    font-size: 24px;
  }

  select {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: rgb(182, 182, 182);
  }
}
</style>

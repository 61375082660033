<template>
  <div class="auth-modal-password-recovery">
    <div class="content">
      <form-password-recovery @login="onLogin"></form-password-recovery>
    </div>
  </div>
</template>

<script>
  import FormPasswordRecovery from '@/components/Forms/FormPasswordRecovery.vue'

  export default {
    components: {
      FormPasswordRecovery
    },

    methods: {
      onLogin() {
        this.openAuthModal({ content: 'login' })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss">
.auth-modal-password-recovery {
  padding: 20px;
}
</style>
<template>
  <ValidationObserver ref="observer"
                      :disabled="disableValidationObserver"
                      slim>
    <form @submit.prevent="submit" class="form-register-client">
      <div class="hint">{{ $t('forms.formRegisterClient.hint') }}</div>
      <div class="form-fields">
        <base-text-box ref="email"
                       name="email"
                       v-model="formData.credentials.email"
                       rules="required|email|email_unique"
                       :rounded="true"
                       :disabled="!!invitedUser"
                       :placeholder="$t('forms.formRegisterClient.emailPlaceholder')">
        </base-text-box>
        <base-text-box ref="password"
                       name="password"
                       :type="showPassword ? 'text': 'password'"
                       v-model="formData.credentials.password"
                       :show-password-visibility-button="true"
                       :show-password="showPassword"
                       rules="required|min:6"
                       :rounded="true"
                       :placeholder="$t('forms.formRegisterClient.passwordPlaceholder')"
                       @passwordVisibilityButtonClick="showPassword = !showPassword">
        </base-text-box>
      </div>
      <div class="form-group agreement">
        <base-checkbox-agreement v-model="agreed"></base-checkbox-agreement>
      </div>
      <div class="form-group submit">
        <base-button class="full-width"
                     type="submit"
                     :disabled="!agreed"
                     :loading="submitting">
          {{ $t('forms.formRegisterClient.createAccount') }}
        </base-button>

        <base-alert type="error" v-if="registerFail">
          {{ $t('login.wrongPasswordOrEmail') }}
        </base-alert>
      </div>
      <div class="social-networks">
        <div class="title">
        <span>
          {{ $t('forms.formRegisterClient.socialNetworks.title') }}
        </span>
        </div>
        <div class="buttons">
          <base-button-icon icon="vkontakte"
                            buttonClass="transparent large"
                            @click="loginViaSocialNetwork('vkontakte')">
          </base-button-icon>
          <base-button-icon icon="facebook"
                            buttonClass="transparent large"
                            @click="loginViaSocialNetwork('facebook')">
          </base-button-icon>
          <base-button-icon icon="google"
                            buttonClass="transparent large"
                            @click="loginViaSocialNetwork('google')">
          </base-button-icon>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import submittable from '@/services/mixins/submittable.js'
  import {
    register,
    loginViaVkontakte,
    loginViaFacebook,
    loginViaGooglePlus
  } from '@/services/auth'

  export default {
    mixins: [
      submittable
    ],

    data() {
      return {
        formData: {
          engine: 'email',
          credentials: {
            email: '',
            password: '',
            role: 'client'
          },
          profile: {
            locale: this.$i18n.locale
          }
        },

        showPassword: false,
        agreed: false,
        loading: false,
        registerFail: false
      }
    },

    created() {
      if (this.invitedUser) {
        this.setInitialFormData()
      }
    },

    methods: {
      setInitialFormData() {
        this.formData.credentials.email = this.invitedUser.email
      },

      onFormSubmit() {
        this.loading = true

        register(this.formData).then((response) => {
          this.loading = false
          this.$emit('success', {
            email:    this.formData.credentials.email,
            password: this.formData.credentials.password
          })

          this.setInvitedUser(null)
        }).catch((error) => {
          this.loading = false
        })
      },

      loginViaSocialNetwork(name) {
        this.loading = true
        this.registerFail = false

        let callbacks = {
          onSuccess: this.onSocialLoginSuccess,
          onFail: this.onSocialLoginFail,
          onClose: () => {
            this.loading = false
          }
        }

        switch (name) {
          case 'vkontakte':
            loginViaVkontakte(callbacks)
            break

          case 'facebook':
            loginViaFacebook(callbacks)
            break

          case 'google':
            loginViaGooglePlus(callbacks)
            break
        }
      },

      onSocialLoginSuccess(response) {
        this.loading = false
        this.registerFail = false

        this.setCurrentUser(response.user)
        this.setAuthorizationTokens({
          accessToken: response.access_token,
          expireToken: response.expire_token
        })

        window.location.reload()

        // This is made to properly redirect user to the home page when page reloads after logging in
        localStorage.setItem('routeToRedirect', JSON.stringify({ name: 'home' }))
      },

      onSocialLoginFail(error) {
        this.loading = false
        this.registerFail = true
      },

      ...mapActions('auth', [
        'setCurrentUser',
        'setAuthorizationTokens',
        'setInvitedUser'
      ])
    },

    computed: {
      ...mapGetters('auth', [
        'invitedUser'
      ])
    }
  }
</script>

<style lang="scss" scoped>


  .form-register-client {
    display: flex;
    flex-direction: column;

    .hint {
      color: #797979;
      margin-bottom: 20px;
      text-align: center;
    }

    .form-fields {
      margin-bottom: 10px;
    }

    .agreement {
      margin-bottom: 30px;
    }

    .submit {
      margin-bottom: 40px;
    }

    .social-networks {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 28px;

        span {
          margin: 0 12px;
          font-size: 14px;
          color: #797979;
        }

        &:before,
        &:after {
          content: "";
          flex-grow: 1;
          height: 2px;
          border-bottom: 1px solid #797979;
          opacity: .5;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;

        button:not(:last-child) {
          margin-right: 28px;
        }
      }
    }

    .alert {
      text-align: center;
    }
  }

  @-webkit-keyframes shake {
    8%, 41% {
      transform: translateX(-10px);
    }
    25%, 58% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-5px);
    }
    92% {
      transform: translateX(5px);
    }
    0%, 100% {
      transform: translateX(0);
    }
  }
</style>

export default {
  client: {
    openGeneralSurvey() {
      (function(a,b,c){var d=window,e=document,f=e.getElementById,g=e.createElement,h=e.getElementsByTagName,i='//surviocdn.com/s1/static/widgets/';if(function(m,n,o){return m._survEmbed=m._survEmbed||[],'function'==typeof m._survEmbed?m._survEmbed(n,o,a):m._survEmbed.push({p:n,c:o,i:a}),!1}(d,b,c),!f.call(e,'survio-share-sc')){var j=g.call(e,'script');j.id='survio-share-sc',j.type='text/javascript',j.src=i+'share.js';var k=h.call(e,'script')[0];k.parentNode.insertBefore(j,k)}if(!f.call(e,'survio-share-css')){var l=g.call(e,'link');l.rel='stylesheet',l.type='text/css',l.id='survio-share-css',l.href=i+'survio-2.css?t='+b,h.call(e,'head')[0].appendChild(l)}})('B6P0Q1Y4W9E7B2L7D','popup',{"url":"https://www.survio.com/survey/p/B6P0Q1Y4W9E7B2L7D","timeout":0})

      localStorage.setItem('wasGeneralSurveyOpenedBefore', true)
    }
  },

  agent: {
    openGeneralSurvey() {
      (function(a,b,c){var d=window,e=document,f=e.getElementById,g=e.createElement,h=e.getElementsByTagName,i='//surviocdn.com/s1/static/widgets/';if(function(m,n,o){return m._survEmbed=m._survEmbed||[],'function'==typeof m._survEmbed?m._survEmbed(n,o,a):m._survEmbed.push({p:n,c:o,i:a}),!1}(d,b,c),!f.call(e,'survio-share-sc')){var j=g.call(e,'script');j.id='survio-share-sc',j.type='text/javascript',j.src=i+'share.js';var k=h.call(e,'script')[0];k.parentNode.insertBefore(j,k)}if(!f.call(e,'survio-share-css')){var l=g.call(e,'link');l.rel='stylesheet',l.type='text/css',l.id='survio-share-css',l.href=i+'survio-2.css?t='+b,h.call(e,'head')[0].appendChild(l)}})('A9N1L0D6L2O1A2W6T','popup',{"url":"https://www.survio.com/survey/p/A9N1L0D6L2O1A2W6T","timeout":0})

      localStorage.setItem('wasGeneralSurveyOpenedBefore', true)
    }
  }
}
<template>
  <div class="mobile-language" ref="currentLocale">
    <div class="current-locale">
      <img class="flag"
           :src="currentLocaleFlag">
    </div>

    <ul class="language-dropdown" ref="languageDropdown">
      <li @click="setLocale('ru_RU')">
        <img src="/pictures/flags/russia.png">
        <span class="title">Русский</span>
      </li>
      <li @click="setLocale('en_US')">
        <img src="/pictures/flags/usa.png">
        <span class="title">English</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import getLocale from '@/services/locale'
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    mounted() {
      this.initLanguageDropdown()
    },

    methods: {
      setLocale(locale) {
        if (!this.authenticated) {
          this.changeRouteLocale(locale)

          return
        }

        profileService.update(this.$currentUser.user_id, { locale }).then(response => {
          this.changeRouteLocale(response.locale)
        })
      },

      changeRouteLocale(locale) {
        this.$router.push({
          query: {
            ...this.$route.query,
            locale
          }
        })

        window.location.reload()
      },

      initLanguageDropdown() {
        tippy(this.$refs.currentLocale, {
          content: this.$refs.languageDropdown,
          interactive: true,
          theme: 'light dropdown-menu',
          animation: 'shift-away',
          arrow: true,
          inertia: true,
          duration: [600, 300]
        })
      }
    },

    computed: {
      ...mapGetters('auth', [
        'authenticated'
      ]),

      currentLocaleFlag() {
        switch (getLocale()) {
          case 'ru_RU': return '/pictures/flags/russia.png'
          case 'en_US': return '/pictures/flags/usa.png'
        }
      },

      currentLocaleTitle() {
        switch (getLocale()) {
          case 'ru_RU': return 'Русский'
          case 'en_US': return 'English'
        }
      }
    }
  }
</script>

<style lang="scss">


  .mobile-language {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    .current-locale {
      display: flex;
      align-items: center;
      cursor: pointer;

      .flag {
        height: 20px;
      }

      .title {
        color: #727272;
        margin-left: 10px;
      }
    }
  }

  .language-dropdown {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      padding: 10px;
      color: #727272;
      font-size: 16px;

      .title {
        margin-left: 10px;
      }

      &:hover {
        cursor: pointer;
        color: $primary-color;
      }
    }

    img {
      height: 20px;
    }
  }
</style>

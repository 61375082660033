<template>
  <div class="type-evaluation">
    <div class="top">
      <icon-arrow-left v-if="cameraOn"
                       class="back"
                       width="36"
                       height="36"
                       @click="cameraOn = false" />

      <icon-cross class="cross"
                  width="36"
                  height="36"
                  @click="close" />
    </div>

    <d-camera v-if="cameraOn"
              @takePhoto="onTakePhoto" />

    <d-progress v-if="inProgress && !cameraOn" />

    <div class="main"
         v-show="!inProgress && !cameraOn">
      <div class="heading">
        <h2>Is this my style?</h2>
        <p>Receive personalized recommendations, uploading your photo, adding your height and selecting your body type</p>
      </div>

      <div class="body">
        <div class="photo">
          <d-photo :photo-url="photo.url" />
          <d-photo-actions @takePhoto="enableCamera"
                           @uploadPhoto="onPhotoUpload" />
          <div class="error"
               v-if="noPhoto">Please select your photo</div>
          <d-tip />
        </div>

        <div class="right">
          <div class="height-wrapper">
            <d-height @input="onHeightInput($event)" />
            <div class="error"
                 v-if="wrongHeight">Height should be between 60 and 260 centimeters.</div>
          </div>
          <d-body-types @select="onBodyTypeSelect" />
          <div class="error" v-if="mlError">{{ mlError }}</div>
        </div>
      </div>

      <button class="submit"
              @click="submit">
        Create style profile
      </button>

      <input ref="fileInput"
             type="file"
             accept="image/png, image/gif, image/jpeg"
             hidden
             @change="selectFile">
    </div>
  </div>
</template>

<script>
import tasksService from '@/services/queries/tasks.js'

import DCamera from './TypeEvaluationCamera.vue'
import DPhoto from './TypeEvaluationPhoto.vue'
import DPhotoActions from './TypeEvaluationPhotoActions.vue'
import DTip from './TypeEvaluationPhotoTip.vue'
import DBodyTypes from './TypeEvaluationBodyTypes.vue'
import DProgress from './TypeEvaluationProgress.vue'
import DHeight from './TypeEvaluationHeight.vue'

export default {
  components: {
    DCamera,
    DPhoto,
    DPhotoActions,
    DTip,
    DBodyTypes,
    DProgress,
    DHeight
  },

  data () {
    return {
      cameraOn: false,

      photo: {
        url: null,
        file: null
      },

      userHeight: null,
      bodyTypeId: null,

      inProgress: false,

      wrongHeight: false,
      noPhoto: false,
      mlError: null,

      archetypeTask: null,
      colortypeTask: null
    }
  },

  methods: {
    async submit () {
      if (!this.validate()) {
        return
      }

      this.inProgress = true

      this.archetypeTask = await this.getArchetype()
      this.colortypeTask = await this.getColortype()

      await this.runTasksCheck()

      this.inProgress = false

      const failedTask = this.getFirstFailedTask()

      if (failedTask) {
        this.mlError = failedTask.ml_error_info?.error_details?.['en']
        return
      }

      await this.updateCurrentUser()

      this.$router.push({
        name: 'myShop',
        params: {
          gender: 'female'
        },

        query: {
          figure_type_id: this.$currentUser.figure_type_id,
          color_type_id: this.$currentUser.color_type_id,
          archetype_id: this.$currentUser.archetype_id
        }
      })

      this.close()
    },

    getFirstFailedTask () {
      if (this.archetypeTask.status === 'FROZEN') {
        return this.archetypeTask
      }

      if (this.colortypeTask.status === 'FROZEN') {
        return this.colortypeTask
      }

      return null
    },

    async runTasksCheck () {
      this.archetypeTask = await tasksService.find(this.archetypeTask.task_id)
      this.colortypeTask = await tasksService.find(this.colortypeTask.task_id)

      const completed = this.archetypeTask.status !== 'PROCESSING' &&
                        this.colortypeTask.status !== 'PROCESSING'

      if (!completed) {
        return setTimeout(() => {
          this.runTasksCheck()
        }, 1000)
      }

      return true
    },

    enableCamera () {
      this.cameraOn = true
    },

    onTakePhoto (photo) {
      this.cameraOn = false
      this.photo = photo
    },

    onPhotoUpload () {
      this.$refs.fileInput.click()
    },

    selectFile (event) {
      const file = event.target.files[0]
      this.photo = {
        file: file,
        url: URL.createObjectURL(file)
      }
    },

    validate () {
      this.wrongHeight = (this.userHeight < 60) || (this.userHeight > 260)
      this.noPhoto = !this.photo.file

      if (this.wrongHeight || this.noPhoto) {
        return false
      }

      return true
    },

    async getArchetype () {
      const payload = {
        tasks: [this.archetypeTaskParams],
        immediate_start: true
      }

      const formData = new FormData()
      formData.append('image', this.photo.file)
      formData.append('payload', JSON.stringify(payload))

      const response = await tasksService.doTask(formData)

      return response.tasks[0]
    },

    async getColortype () {
      const payload = {
        tasks: [this.colortypeTaskParams],
        immediate_start: true
      }

      const formData = new FormData()
      formData.append('image', this.photo.file)
      formData.append('payload', JSON.stringify(payload))

      const response = await tasksService.doTask(formData)

      return response.tasks[0]
    },

    onBodyTypeSelect (id) {
      this.bodyTypeId = id
    },

    onHeightInput (height) {
      this.userHeight = height
    },

    close () {
      this.$emit('close')
    },

    ...mapActions('auth', [
      'updateCurrentUser'
    ])
  },

  computed: {
    archetypeTaskParams () {
      return {
        task_type: 'CLASSIFY_ARCHETYPE',
        client_height: +this.userHeight,
        client_gender: 'female',
        client_figure_type_id: this.bodyTypeId,
        immediate_start: true
      }
    },

    colortypeTaskParams () {
      return {
        task_type: 'CLASSIFY_COLOR_TYPE'
      }
    },

    ...mapGetters('handbooks', [
      'getArchetypeById'
    ])
  }
}
</script>

<style lang="scss" scoped>
.type-evaluation {
  max-height: 655px;
  width: 1000px;
}

.height-wrapper {
  margin-bottom: 40px;
}

.top {
  position: relative;
  display: flex;
  justify-content: space-between;

  .back {
    cursor: pointer;
  }

  .cross {
    margin-left: auto;
    cursor: pointer;
  }
}

.main {
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #000;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 32px;
  }

  p {
    font-size: 16px;
    margin: 10px 0 30px;
  }
}

.body {
  display: flex;
  justify-content: space-between;

  padding: 0 20px;

  .photo {
    width: 280px;
  }
}

.right {
  flex: 1 0;
  margin-left: 20px;
}

.error {
  color: red;
}

.submit {
  width: 200px;
  height: 60px;
  margin-left: auto;
  background: #000;
  color: #fff;
  font-size: 16px;
  border: 0;
  cursor: pointer;
}
</style>

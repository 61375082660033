<template>
  <div class="image"
       :style="backgroundStyles" />
</template>

<script>
export default {
  props: {
    photoUrl: {
      required: false,
      default: null
    }
  },

  computed: {
    backgroundStyles () {
      const image = this.photoUrl ?? '/pictures/photo_placeholder.png'
      const size = this.photoUrl ? 'cover' : ''

      return {
        'background-image': 'url(' + image + ')',
        'background-size': size
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  height: 310px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
</style>

<template>
  <div class="register-success">
    <div class="heading-accent text-center">
      {{ $t('auth.authModal.authModalRegisterSuccess.title') }}
    </div>
    <div class="description">
      {{ $t('auth.authModal.authModalRegisterSuccess.message') }}
    </div>
    <base-button class="full-width"
                 @click="openLoginModal">
      {{ $t('auth.authModal.authModalRegisterSuccess.signIn') }}
    </base-button>
  </div>
</template>

<script>
  export default {
    methods: {
      openLoginModal() {
        this.openAuthModal({ content: 'login' })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss">
  .register-success {
    width: 400px;

    .description {
      font-size: 14px;
      color: #797979;
      margin-bottom: 30px;
      text-align: center;
    }
  }
</style>

<template>
  <base-modal name="typeDefinition" :width="1060">
    <type-evaluation @close="$modal.hide('typeDefinition')" />
  </base-modal>
</template>

<script>
import tasksService from '@/services/queries/tasks.js'
import TypeEvaluation from '@/components/TypeEvaluation/TypeEvaluation.vue'

export default {
  components: {
    TypeEvaluation
  },

  data () {
    return {
      inProgress: false,
      photoUrl: null,
      photoFile: null,
      photoCaptureIsOpen: false,
      cameraIsAllowed: false,
      userHeight: null,
      heightDimens: 'inch',
      width: 320,
      height: 0,
      showRecommendations: false,
      recommendations: [],

      bodyTypes: [
        { name : 'Hourglass', image: 'hourglassBody', active: false, id: 4},
        { name : 'Inverted triangle', image: 'invertedTriangleBody', active: true, id: 2},
        { name : 'Rectangle', image: 'rectangleBody', active: false, id: 3},
        { name : 'Triangle', image: 'triangleBody', active: false, 'id': 1}
      ]
    }
  },

  computed: {
    ...mapGetters('handbooks', [
      'getArchetypeById'
    ]),

    photoUrlImg() {
      return this.photoUrl
        ? this.photoUrl
        : '/pictures/photo_placeholder.png'
    },

    backgroundStyles() {
      return {
        'background-image': 'url(' + this.photoUrlImg + ')',
        'background-size': this.photoUrl ? 'cover' : '',
      }
    },

    selectedBodyTypeId() {
      return this.bodyTypes.find(type => type.active).id
    },

    userCmHeight() {
      return this.heightDimens === 'inch'
      ? (Number(this.userHeight) * 2.54).toFixed(0)
      : this.userHeight
    }
  },

  methods: {
    async getStyleProfile() {
      if((this.userCmHeight < 60) || (this.userCmHeight > 260)) {
        return
      } else if (!this.photoFile) {
        return
      } else {
        this.inProgress = true
      }

      const formData = new FormData();

      const options = {
        tasks: [
          {
            task_type: "CLASSIFY_ARCHETYPE",
            client_height: +this.userCmHeight,
            client_gender: 'female',
            client_figure_type_id: this.selectedBodyTypeId,
          },
          {
            task_type: "CLASSIFY_COLOR_TYPE",
          },
        ],
        immediate_start: true,
      };

      formData.append("image", this.photoFile);
      formData.append("payload", JSON.stringify(options));

      try {
        await tasksService.setTasks(formData)
        await this.updateCurrentUser()

        this.$router.push({
          name: 'myShop',
          params: {
            gender: 'female'
          },

          query: {
            figure_type_id: this.$currentUser.figure_type_id,
            color_type_id: this.$currentUser.color_type_id
          }
        })

        this.close()

        // const userParams = await lookService.getCurrentProfile()
        //
        // const recommendationsResponse = await clothingService.get({
        //   gender: 'FEMALE',
        //   total_omit: 'true',
        //   color_filter: 'monochrome',
        //   compatible_archetype_codes: this.getArchetypeById(userParams.archetype_id).code,
        //   colortype: userParams.color_type_id,
        //   offset: 0,
        //   limit: 60
        // })


        // this.recommendations = recommendationsResponse.items
        // this.showRecommendations = true
        // this.inProgress = false

      } catch (error) {
        console.log(error);
      }
    },

    ...mapActions('auth', [
      'updateCurrentUser'
    ]),

    close() {
      this.$modal.hide('typeDefinition')
      this.photoCaptureIsOpen = false
    },

    openPartnerUrl(item) {
      let routeData = this.$router.resolve({name: 'clothingItem', params: {id: item.clothing_item_id }});
      window.open(routeData.href, '_blank');
    },

    initVideoCapture() {
      this.cameraIsAllowed = navigator.mediaDevices

      navigator.mediaDevices?.getUserMedia({
        video: true,
        audio: false
      }).then(stream => {
        this.$refs.video_player.srcObject = stream
        this.$refs.video_player.play()
      })
    },

    clickOnFileUploader() {
      this.$refs.fileInput.click()
    },

    takePhoto() {
      let video_player = this.$refs.video_player
      let picture = this.$refs.canvas

      let image = null;
      picture.width = video_player.videoWidth;
      picture.height = video_player.videoHeight;

      const ctx = picture.getContext("2d");
      ctx.save();
      ctx.scale(-1, 1);
      ctx.drawImage(video_player, 0, 0, video_player.videoWidth * -1, video_player.videoHeight);
      ctx.restore();

      let img = picture.toDataURL("image/png");

      this.photoUrl = img;

      picture.toBlob(
        (blob) => {
          const file = new File([blob], `${Date.now()}.png`, {
            type: "image/png",
            lastModified: Date.now(),
          });

          this.photoFile = file;
        },
        "image/png",
        1
      );

      this.photoCaptureIsOpen = false
      this.$refs.video_player.srcObject.getTracks()[0].stop()
      this.$refs.video_player.pause()
    },

    openPhotoCapture() {
      this.photoCaptureIsOpen = true
      this.initVideoCapture()
    },

    selectFile(event) {
      this.photoFile = event.target.files[0]
      this.photoUrl = (URL.createObjectURL(this.photoFile))
    },

    selectBodyType(bodyType) {
      this.bodyTypes.forEach(type => {
        type.active = type.name === bodyType
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .style-generator {
    max-height: 655px;
    width: 1000px;
    display: flex;
    flex-direction: column;

    .loader {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        color: rgb(53, 66, 72) !important;
        font-size: 16px !important;
        width: 280px !important;
        letter-spacing: -0.5px !important;
        text-align: center !important;
        margin-top: 20px !important;
      }
    }

    .camera {
      -webkit-box-align: center !important;
      -webkit-box-pack: center !important;
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;

      .picture-capture {
        position: relative !important;
        width: 500px !important;
        height: 600px !important;
        background-color: rgb(208, 208, 208) !important;

        .video {
          object-fit: cover !important;
          transform: rotateY(
          180deg) !important;
        }

        .picture-frame {
          position: absolute !important;
          top: 0px !important;
          left: 0px !important;
          width: 100% !important;
          height: 100% !important;
          background-position: 50% 50% !important;
          background-repeat: no-repeat !important;

          .title {
            position: absolute !important;
            top: 45% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            font-size: 19px !important;
            width: 215px !important;
            text-align: center !important;
            color: rgb(255, 255, 255) !important;
          }
        }

        button {
          -webkit-box-align: center !important;
          -webkit-box-pack: center !important;
          position: absolute !important;
          bottom: 0px !important;
          left: 0px !important;
          width: 100% !important;
          height: 60px !important;
          background: rgb(0, 0, 0) !important;
          color: white !important;
          border: none !important;
          outline: none !important;
          font-family: Roboto, sans-serif;
          font-size: 16px !important;
          transition: all 0.1s ease 0s !important;
          cursor: pointer !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
        }
      }
    }

    .recommendations-wrapper {
      position: relative;

      display: flex !important;
      flex-wrap: wrap !important;
      overflow-y: scroll ;
      padding: 0px 0px 20px 30px !important;
      margin-top: 10px !important;

      .item {
        cursor: pointer;
        height: 300px;
        width: 223px !important;
        padding: 0px 5px !important;
        -webkit-box-direction: normal !important;
        -webkit-box-orient: vertical !important;
        display: flex !important;
        flex-direction: column !important;
        height: 100% !important;

        background-size: contain !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
      }
    }

    .upper-panel {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .message {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        font-size: 32px;
        font-family: Roboto, sans-serif;
        color: #000;

        &.danger {
          color: red;
          font-size: 16px;
        }
      }

      .cross {
        background: white;
        border: none;
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: all 0.3s ease 0s;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          width: 25px;
          height: 3px;
          background: black;
          transform: translate(-50%, -50%) rotateZ(-45deg);
          transition: all 0.1s ease 0s;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          width: 25px;
          height: 3px;
          background: black ;
          transform: translate(-50%, -50%) rotateZ(45deg);
          transition: all 0.1s ease 0s;
        }

        &:hover {
          background: black;


          &::before {
            background: white;
          }

          &::after {
            background: white;
          }
        }
      }
    }

    header {
      display: flex;
      flex-direction: column;
      color: black;
      align-items: center;

      h2 {
        margin: 0;
        padding: 0;
        font-family: Roboto, sans-serif;
        font-size: 32px;
        color: black;
        font-weight: normal;
      }

      p {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        margin: 10px 0px 30px;
      }
    }

    body {
      height: 473px;
      padding: 0 20px;

      display: flex;
      justify-content: space-between;

      .photo-frame {
        position: relative !important;
        width: 280px !important;
        height: 370px !important;
        background: rgb(246, 246, 246) !important;
        user-select: none !important;

        >.image {

          width: 100% !important;
          height: 310px !important;
          background-position: 50% 50% !important;
          background-repeat: no-repeat !important;

          width: 100% !important;
          height: 310px !important;
          background-position: 50% 50% !important;
          background-repeat: no-repeat !important;
        }

        .options {
          width: 100% !important;
          display: flex !important;

          .option {
            &:hover {
              background: rgb(221, 221, 221) !important;
            }

            -webkit-box-align: center !important;
            -webkit-box-pack: center !important;
            position: relative !important;
            width: 100% !important;
            height: 60px !important;
            background: rgb(233, 233, 233) !important;
            color: rgb(27, 33, 36) !important;
            border: none !important;
            outline: none !important;
            font-family: Roboto, sans-serif;
            font-size: 16px !important;
            transition: all 0.1s ease 0s !important;
            cursor: pointer !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;

            .icon {
              position: relative !important;
              width: 30px !important;
              height: 30px !important;
              margin-right: 5px !important;
              background-position: 50% 50% !important;
              background-repeat: no-repeat !important;
            }
          }
        }

        .tip {
          position: relative;
          display: flex !important;
          justify-content: center;

          padding-bottom: 10px !important;
          transition: all 0.1s ease 0s !important;
          cursor: pointer !important;
          margin-top: 15px !important;

          .text {
            font-family: Roboto, sans-serif;
            font-size: 14px !important;
            line-height: 16px;
            color: rgb(157, 157, 157) !important;
          }

          .icon {
            width: 40px;
            height: 40px;
          }

        }
      }

      .body-type {
        display: flex;
        flex-direction: column;

        .height {
          -webkit-box-align: center;
          -webkit-box-pack: justify;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 280px;
          height: 60px;
          border: 1px solid rgb(233, 233, 233);
          padding: 0px 15px;

          .title {
              font-family: Roboto, sans-serif;
              font-size: 20px;
              color: rgb(208, 208, 208);
          }

          input {
            border-top: none;
            border-right: none;
            border-left: none;
            border-image: initial;
            width: 46px;
            border-bottom: 1px solid;
            outline: none;
            text-align: center;
            font-family: Roboto, sans-serif;
            font-size: 24px;
          }

          select {
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-family: Roboto, sans-serif;
            font-size: 14px;
            color: rgb(182, 182, 182);
          }


        }

        .figures {
          margin-top: 22px;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 16px;
            color: rgb(208, 208, 208);
            text-transform: uppercase;

            -webkit-box-align: center;
            -webkit-box-pack: start;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 17px;
          }

          .types {
            display: flex;

            .type {
              -webkit-box-align: center !important;
              -webkit-box-pack: justify !important;
              -webkit-box-direction: normal !important;
              -webkit-box-orient: vertical !important;
              width: 150px !important;
              height: 250px !important;
              border: 1px solid rgb(233, 233, 233) !important;
              display: flex !important;
              flex-direction: column !important;
              justify-content: space-around !important;
              align-items: center !important;
              cursor: pointer !important;
              transition: all 0.1s ease 0s !important;

              bottom: 1px ;
              background: white;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content:space-around;

              &:hover {
                background-color: rgb(233, 233, 233) !important;
              }

              &:active {
                background-color: rgb(221, 221, 221) !important;
                border-color: rgb(221, 221, 221) !important;
              }

              &.active {
                background-color: rgb(233, 233, 233) !important;
                cursor: default !important;
                pointer-events: none !important;
              }

              .image {
                display: flex;
                justify-content: center;
                height: 180px;
                width: 100%;
              }

              .title {
                text-transform: none;
                font-family: Roboto, sans-serif;
                font-size: 14px;
                color: rgb(27, 33, 36);
              }
            }

            & > * {
              width: 150px;
              height: 250px;
              background: rgb(242, 242, 242);
            }
          }

        }

        button {
          margin-top: 30px;
          &:hover {
            background: lighten($color: black, $amount: 20);
          }

          font-family: Roboto, sans-serif;
          font-weight: bold;
          color: white;

          background: rgb(0, 0, 0);
          color: white;
          width: 280px;
          -webkit-box-align: center;
          -webkit-box-pack: center;
          position: relative;
          box-sizing: border-box;
          height: 60px;
          padding: 5px 10px;
          outline: none;
          transition: all 0.3s ease;
          font-size: 16px;
          cursor: pointer;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }

        display: flex;
      }
    }

    footer {
      display: flex;
      justify-content: center;
    }


  }

  .close-arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px !important;
    height: 30px !important;
    opacity: 0.6 !important;
    cursor: pointer !important;
    transition: all 0.1s ease 0s !important;

    &:before {
      position: absolute !important;
      top: 44% !important;
      left: 25% !important;
      content: "" !important;
      width: 15px !important;
      height: 3px !important;
      background: black !important;
      transform-origin: left center !important;
      transform: rotateZ(45deg) !important;
      transition: all 0.1s ease 0s !important;
    }

    &:after {
      position: absolute !important;
      top: 49% !important;
      left: 25% !important;
      content: "" !important;
      width: 15px !important;
      height: 3px !important;
      background: black !important;
      transform-origin: left center !important;
      transform: rotateZ(-45deg) !important;
      transition: all 0.1s ease 0s !important;
    }
  }

// FIXME: there is a problem when there are more content
// than it is now (content starts to get out of the screen)
.base-modal-brand {
  overflow: auto;
}
</style>

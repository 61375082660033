<template>
  <div class="layout" :class="$mq">
    <site-header></site-header>
    <router-view class="content"></router-view>

    <portal-target name="modals" multiple></portal-target>
    <portal-target name="clothingFiltersPanel" slim></portal-target>
    <portal-target name="slide-menus"></portal-target>
    <survey-modal/>

    <type-definition-modal />
    <wear-generation-modal />
  </div>
</template>

<script>
import SiteHeader from '@/components/SiteHeader/SiteHeader.vue'
import SurveyModal from '@/components/SurveyModal.vue'
import TypeDefinitionModal from '@/components/TypeDefinitionModal.vue'
import WearGenerationModal from '@/components/WearGenerationModal.vue'

export default {
  components: {
    SiteHeader,
    SurveyModal,
    TypeDefinitionModal,
    WearGenerationModal
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  padding-top: $header-height;

  & > .content {
    min-height: 100%;
  }
}
</style>

<template>
  <div class="auth-modal-login">
    <div class="content">
      <div class="heading-accent text-center">
        {{ $t('auth.authModal.authModalLogin.title') }}
      </div>
      <form-login @askPasswordRecovery="openPasswordRecoveryModal"></form-login>
    </div>

    <portal to="authModalFooter">
      <div class="footer-content">
        <div class="title">{{ $t('auth.authModal.authModalLogin.signUp.title') }}</div>
        <a href="#" @click.prevent="openRegisterModal">
          {{ $t('auth.authModal.authModalLogin.signUp.link') }}
        </a>
      </div>
    </portal>
  </div>
</template>

<script>
  import FormLogin from '@/components/Forms/FormLogin.vue'

  export default {
    components: {
      FormLogin
    },

    methods: {
      openRegisterModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'register' })
      },

      openPasswordRecoveryModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'passwordRecovery' })
      },

      toggleError(errorState) {
        this.loginFail = errorState
        this.$emit('errorStateChange', this.loginFail)
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss">
  .auth-modal-login {
    & > .content {
      display: flex;
      flex-direction: column;
      margin: 20px;
      width: 500px;
    }
  }

  .footer-content {
    text-align: center;

    .title {
      font-size: 16px;
      color: #fff;
      margin-bottom: 4px;
    }

    > a {
      color: #fff;
      font-size: 14px;
    }
  }
</style>

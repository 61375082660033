const baseURL = 'crm/tasks'

export default {
  async find (id) {
    const response = await axios.get(`${baseURL}/${id}`)

    return response.data
  },

  async doTask (data) {
    const response = await axios.post(baseURL, data)

    return response.data
  },

  async setTasks (formData) {
    const response = await axios.post(baseURL, formData)

    return response.data
  },
}

<template>
  <base-modal name="wearGeneration"
              :width="1060"
              @beforeOpen="beforeOpen($event)">
    <div class="style-generator">
      <div class="upper-panel">
        <div v-if="showGeneratedLooks"
          class="close-arrow"
          @click="showGeneratedLooks = false"/>

        <div v-if="showGeneratedLooks" class="message">
          Recommendations
        </div>

        <button
          class="close-icon"
          @click="close"/>
      </div>

      <div class="generated-looks" v-if="finishedLooks.length && showGeneratedLooks">
        <div class="look" v-for="look in finishedLooks" :key="look.index">
          <img @click="openLookUrl(look)" :src="look.previewUrl">
        </div>
      </div>

      <template v-else-if="!inProgress">
        <header>
          <h2>How to wear ?</h2>
          <p>Upload a photo of any item from your wardrobe</p>
        </header>
        <body>
          <div class="photo-frame">
            <div
              class="image"
              :style="imageStyles"/>
            <div class="options">
              <input
                ref="fileInput"
                type="file"
                hidden
                @change="selectFile"
                accept="image/png, image/gif, image/jpeg">

              <button @click="uploadPhoto">
                <svg class="icon">
                  <use xlink:href="#alternativeUpload"></use>
                </svg>
                  Upload
              </button>
            </div>
          </div>
          <button @click="completeOutfit">Complete the outfit</button>
        </body>
      </template>

      <div v-if="inProgress" class="loader">
        <img  src="../assets/img/preloader.gif" alt="this slowpoke moves"  width="320" height="62" />
        <div class="title">
          <div>Processing your request may take up to 20 seconds. But style is worth the wait.</div>
          <div>Thanks for your patience.</div>
        </div>
      </div>

  </div>
  </base-modal>
</template>

<script>
import lookService from '@/services/queries/lookQueries.js'

export default {
  data () {
    return {
      photoFile: null,
      photoUrl: null,
      inProgress: false,
      showGeneratedLooks: false,
      finishedLooks: [],

      clothingItemId: null
    }
  },

  computed: {
    photoUrlImg () {
      return this.photoUrl ? this.photoUrl : '/pictures/wear_placeholder.png'
    },

    imageStyles () {
      return {
        'background-image': 'url(' + this.photoUrlImg + ')',
        'background-size': this.photoUrl ? 'cover' : '',
      }
    }
  },

  methods: {
    beforeOpen (event) {
      console.log('beforeOpen', event)
      this.clothingItemId = event.params ? event.params.clothingItemId : null
    },

    close () {
      this.inProgress = false
      this.showGeneratedLooks = false
      this.finishedLooks = []

      this.$modal.hide('wearGeneration')
    },

    async getLooksByClothingId() {
      this.inProgress = true;

      let options = {
        is_public: false,
        is_draft: true,
        client_id: this.$currentUser.client_id,
        generate: true,
        mode: "CLOTHING_ITEM",
        clothing_item_id: this.clothingItemId,
        title: "clothing",
        plus_size: false,
        gender: "female"
      }

      try {
        await lookService.store(this.$currentUser.client_id, options)
        this.runLoop();
      } catch (error) {
        console.log(error)
      }
    },

    openLookUrl(look) {
      let routeData = this.$router.resolve({name: 'look', params: {
        userId: this.$currentUser.id,
        lookId: look.id
      }});

      window.open(routeData.href, '_blank');
    },

    uploadPhoto() {
      this.$refs.fileInput.click()
    },

    async completeOutfit() {
      this.inProgress = true

      const formData = new FormData()

      const options = {
        "is_public": false,
        "is_draft": true,
        "client_id": this.$currentUser.client_id,
        "generate": true,
        "mode": "PHOTO_ITEM",
        "title": "photo",
      };

      formData.append("image", this.photoFile)
      formData.append("payload", JSON.stringify(options))

      await lookService.storeLooksByPhoto(this.$currentUser.client_id, formData)
      this.runLoop()
    },

    async runLoop() {
      let completed = false

      const looks = await lookService.get(this.$currentUser.client_id, {
        sorting: 'time_created{desc}',
        limit: 5
      })

      const statuses = looks.items.map(item => item.status)

      completed = statuses.every(status => (status === 'SUCCESS' || status === 'FAILED'))

      if (!completed) {
        return setTimeout(() => {
          this.runLoop()
        }, 2000)
      }

      this.close()

      this.$router.push({
        name: 'myOutfits'
      })
    },

    selectFile(event) {
      this.photoFile = event.target.files[0]
      this.photoUrl = (URL.createObjectURL(this.photoFile))
    }
  },

  watch: {
    clothingItemId(value) {
      if(value) {
        this.getLooksByClothingId()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .style-generator {
    width: 1000px;
    min-height: 590px;
    display: flex;
    flex-direction: column;

    .loader {
      height: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        color: rgb(53, 66, 72) !important;
        font-size: 16px !important;
        width: 280px !important;
        letter-spacing: -0.5px !important;
        text-align: center !important;
        margin-top: 20px !important;
      }
    }

    .upper-panel {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .close-icon {
        background: white;
        border: none;
        position: relative;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: all 0.3s ease 0s;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          width: 25px;
          height: 3px;
          background: black;
          transform: translate(-50%, -50%) rotateZ(-45deg);
          transition: all 0.1s ease 0s;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          content: "";
          width: 25px;
          height: 3px;
          background: black ;
          transform: translate(-50%, -50%) rotateZ(45deg);
          transition: all 0.1s ease 0s;
        }

        &:hover {
          background: black;


          &::before {
            background: white;
          }

          &::after {
            background: white;
          }
        }
      }

      .message {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        font-size: 32px;
        font-family: Roboto, sans-serif;
        color: #000;
      }
    }

    .generated-looks {
        height: 650px;
        display: flex !important;
        flex-wrap: wrap !important;
        overflow-y: scroll;
        padding: 0px 35px 20px !important;
        margin-top: 30px !important;

      .look {
        -webkit-box-align: center !important;
        -webkit-box-pack: center !important;
        img {
          width: 250px !important;
          height: 250px !important;
        }

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border: 1px solid rgb(229, 229, 229) !important;
        padding: 8px !important;
        cursor: pointer !important;
      }
    }

    .close-arrow {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px !important;
      height: 30px !important;
      opacity: 0.6 !important;
      cursor: pointer !important;
      transition: all 0.1s ease 0s !important;

      &:before {
        position: absolute !important;
        top: 44% !important;
        left: 25% !important;
        content: "" !important;
        width: 15px !important;
        height: 3px !important;
        background: black !important;
        transform-origin: left center !important;
        transform: rotateZ(45deg) !important;
        transition: all 0.1s ease 0s !important;
      }

      &:after {
        position: absolute !important;
        top: 49% !important;
        left: 25% !important;
        content: "" !important;
        width: 15px !important;
        height: 3px !important;
        background: black !important;
        transform-origin: left center !important;
        transform: rotateZ(-45deg) !important;
        transition: all 0.1s ease 0s !important;
      }
    }

    header {
      display: flex;
      flex-direction: column;
      color: black;
      align-items: center;

      h2 {
        margin: 0;
        padding: 0;
        font-family: Roboto, sans-serif;
        font-size: 32px;
        color: black;
        font-weight: normal;
      }

      p {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        margin: 10px 0px 30px;
      }
    }

    body {
      height: 473px;
      padding: 0 20px;

      display: flex;
      justify-content: space-between;

      height: 473px;
      padding: 0 20px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .photo-frame {
        position: relative !important;
        width: 280px !important;
        height: 370px !important;
        background: rgb(246, 246, 246) !important;
        user-select: none !important;

        >.image {
          width: 100% !important;
          height: 310px !important;
          background-position: 50% 50% !important;
          background-repeat: no-repeat !important;
        }

        .options {

          .icon {
            position: relative !important;
            width: 30px !important;
            height: 30px !important;
            margin-right: 5px !important;
            background-position: 50% 50% !important;
            background-repeat: no-repeat !important;
          }

          button {
            -webkit-box-align: center !important;
            -webkit-box-pack: center !important;
            position: relative !important;
            width: 100% !important;
            height: 60px !important;
            background: rgb(233, 233, 233) !important;
            color: rgb(27, 33, 36) !important;
            border: none !important;
            outline: none !important;
            font-family: 'Roboto', sans-serif;
            font-size: 16px !important;
            transition: all 0.1s ease 0s !important;
            cursor: pointer !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
          }
        }
      }

      button {
        &:hover {
          background: lighten($color: black, $amount: 20);
        }

        font-family: Roboto, sans-serif;
        font-weight: bold;
        color: white;

        background: rgb(0, 0, 0);
        color: white;
        width: 280px;
        -webkit-box-align: center;
        -webkit-box-pack: center;
        position: relative;
        box-sizing: border-box;
        height: 60px;
        padding: 5px 10px;
        outline: none;
        transition: all 0.3s ease;
        font-size: 16px;
        cursor: pointer;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }
    }

    footer {
      display: flex;
      justify-content: center;
    }
  }
// FIXME: there is a problem when there are more content
// than it is now (content starts to get out of the screen)
.base-modal-brand {
  overflow: auto;
}
</style>

<template>
  <div class="mobile-cart"
       @click="goToCart">
    <base-icon icon="cart"></base-icon>
    <span class="number-of-items"
          v-if="numberOfItems > 0">{{ numberOfItems }}</span>
  </div>
</template>

<script>
  export default {
    methods: {
      goToCart() {
        this.$router.push({ name: 'cart' })
      }
    },

    computed: {
      ...mapGetters('cart', {
        cartClothingItems: 'getClothingItems'
      }),

      numberOfItems() {
        return this.cartClothingItems.length
      }
    }
  }
</script>

<style lang="scss">


  .mobile-cart {
    display: flex;
    align-items: center;
    height: 100%;

    .number-of-items {
      margin-left: 4px;
      font-weight: 600;
      font-size: 12px;
    }

    color: $primary-color;
  }
</style>

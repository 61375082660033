<template>
  <div class="camera">
    <div class="canvas-capture">
      <video ref="videoPlayer"
             class="video"
             height="600"
             width="500">
      </video>

      <div class="canvas-frame" ref="photo" >
        <svg width="500" height="600">
          <use xlink:href="#mask"/>
        </svg>

        <div v-if="cameraAllowed" class="title">
          Look to the camera, not on the screen
        </div>

        <div v-else class="title">
          Please unblock camera for this site
        </div>
      </div>

      <canvas hidden ref="canvas"></canvas>

      <button class="submit"
              @click="takePhoto">
        Take photo
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      captureOpen: false,
      cameraAllowed: false,
      photo: null
    }
  },

  mounted () {
    this.cameraAllowed = navigator.mediaDevices

    navigator.mediaDevices?.getUserMedia({
      video: true,
      audio: false
    }).then(stream => {
      this.$refs.videoPlayer.srcObject = stream
      this.$refs.videoPlayer.play()
    })
  },

  methods: {
    takePhoto () {
      const videoPlayer = this.$refs.videoPlayer
      const canvas = this.$refs.canvas

      canvas.width = videoPlayer.videoWidth
      canvas.height = videoPlayer.videoHeight

      const ctx = canvas.getContext('2d')
      ctx.save()
      ctx.scale(-1, 1)
      ctx.drawImage(videoPlayer, 0, 0, videoPlayer.videoWidth * -1, videoPlayer.videoHeight)
      ctx.restore()

      this.photoUrl = canvas.toDataURL('image/png')

      canvas.toBlob(blob => {
        const file = new File([blob], `${Date.now()}.png`, {
          type: 'image/png',
          lastModified: Date.now(),
        })

        this.$emit('takePhoto', {
          url: canvas.toDataURL('image/png'),
          file
        })
      }, 'image/png', 1)

      this.captureOpen = false
      this.$refs.videoPlayer.srcObject.getTracks()[0].stop()
      this.$refs.videoPlayer.pause()
    },
  }
}
</script>

<style lang="scss" scoped>
.camera {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-height: 600px;

  .canvas-capture {
    position: relative;
    width: 500px;
    height: 600px ;
    background-color: rgb(208, 208, 208);
  }

  .video {
    object-fit: cover;
    transform: rotateY(180deg);
  }

  .canvas-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    .title {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 19px;
      width: 215px;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 60px;

    background: #000;
    color: #fff;
    border: none;
    outline: none;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    transition: all 0.1s ease 0s;
    cursor: pointer;
  }
}
</style>

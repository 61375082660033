<template>
  <div class="auth-modal-register">
    <div class="content">
      <div class="header heading-accent text-center">
        {{ $t('auth.authModal.authModalRegister.title') }}
      </div>

      <ul class="tabs-header">
        <li :class="{ active: show === 'registerAgent' }"  @click="show = 'registerAgent'">
          {{ $t('auth.authModal.authModalRegister.agentAccount') }}
        </li>
        <li :class="{ active: show === 'registerClient' }" @click="show = 'registerClient'">
          {{ $t('auth.authModal.authModalRegister.clientAccount') }}
        </li>
      </ul>

      <form-register-agent  v-show="show === 'registerAgent'"
                            @success="onRegisterSuccess"></form-register-agent>
      <form-register-client v-show="show === 'registerClient'"
                            @success="onRegisterSuccess"></form-register-client>
    </div>

    <portal to="authModalFooter">
      <div class="footer-content">
        <div class="title">{{ $t('auth.authModal.authModalRegister.login.title') }}</div>
        <a href="#" @click.prevent="openLoginModal">
          {{ $t('auth.authModal.authModalRegister.login.link') }}
        </a>
      </div>
    </portal>
  </div>
</template>

<script>
  import FormRegisterClient from '@/components/Forms/FormRegisterClient.vue'
  import FormRegisterAgent from '@/components/Forms/FormRegisterAgent.vue'

  import { login } from '@/services/auth'

  export default {
    components: {
      FormRegisterClient,
      FormRegisterAgent
    },

    data() {
      return {
        show: 'registerAgent'
      }
    },

    methods: {
      openLoginModal() {
        this.$emit('close')
        this.openAuthModal({ content: 'login' })
      },

      onRegisterSuccess(credentials) {
        let data = {
          engine: 'email',
          credentials,
          profile: {
            'gender': 'FEMALE',
            'meta_data': { 'test': true }
          },
          long_live: true
        }

        login(data).then((response) => {
          this.setCurrentUser(response.user)
          this.setAuthorizationTokens({
            accessToken: response.access_token,
            expireToken: response.expire_token
          })

          window.location.reload()

          // This is made to properly redirect user to the home page when page reloads after logging in
          localStorage.setItem('routeToRedirect', JSON.stringify({ name: 'home' }))
        })
      },

      ...mapActions('system', [
        'openAuthModal'
      ]),

      ...mapActions('auth', [
        'setCurrentUser',
        'setAuthorizationTokens',
      ])
    }
  }
</script>

<style lang="scss">
  .auth-modal-register {
    .header {
      margin-bottom: 30px;
    }

    & > .content {
      display: flex;
      flex-direction: column;
      margin: 20px;
      width: 500px;
    }

    .tabs-header {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0 0 30px 0;

      & > li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding: 14px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        color: $primary-color;
        border: 1px solid $primary-color;
        transition: all .2s;
        cursor: pointer;
        user-select: none;

        &:first-child {
          border-radius: 6px 0 0 6px;
          border-right: none;
        }

        &:last-child {
          border-radius: 0 6px 6px 0;
          border-left: none;
        }

        &:hover,
        &.active {
          color: #fff;
          border-color: transparent;
        }

        &.active {
          background-color: $primary-color;
          cursor: default;
        }

        &:not(.active):hover {
          background-color: lighten($primary-color, 10%);
        }
      }
    }
  }

  .footer-content {
    text-align: center;

    .title {
      font-size: 16px;
      color: #fff;
      margin-bottom: 4px;
    }

    > a {
      color: #fff;
      font-size: 14px;
    }
  }
</style>

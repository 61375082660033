<template>
  <div>
    <mq-layout :mq="['mobile', 'tablet']">
      <site-header-mobile></site-header-mobile>
    </mq-layout>
    <mq-layout mq="desktop">
      <site-header-desktop></site-header-desktop>
    </mq-layout>
  </div>
</template>

<script>
  import SiteHeaderDesktop from './SiteHeaderDesktop/SiteHeader.vue'
  import SiteHeaderMobile  from './SiteHeaderMobile/SiteHeaderMobile.vue'

  export default {
    components: {
      SiteHeaderDesktop,
      SiteHeaderMobile
    }
  }
</script>
<template>
  <div class="mobile-navigation">
    <div class="mobile-navigation-trigger" @click="open">
      <base-icon icon="burger"></base-icon>
    </div>

    <transition enter-active-class="animated slideInLeft"
                leave-active-class="animated slideOutLeft">
      <div class="mobile-navigation-panel"
           v-if="isOpen">
        <base-icon icon="cross"
                   class="close"
                   @click="close"></base-icon>

        <ul class="menu">
          <li class="menu-item"
              v-if="!$currentUser || !$currentUser.isAdmin">
            <router-link :to="{ name: 'catalogue', params: { gender: 'female' } }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.catalogue') }}
            </router-link>
          </li>
          <li class="menu-item"
              v-if="!$currentUser || !$currentUser.isAdmin">
            <router-link :to="{ name: 'lookFeed' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.feed') }}
            </router-link>
          </li>
          <!-- <li class="menu-item"
              v-if="!$currentUser || !$currentUser.isAdmin">
            <router-link :to="{ name: 'agentsBoard' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.agentsBoard') }}
            </router-link>
          </li> -->
          <!-- <li class="menu-item"
              v-if="!$currentUser || !currentUser.isAdmin">
            <router-link :to="{ name: 'persons' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.persons') }}
            </router-link>
          </li> -->
          <li class="menu-item" v-if="currentUser && currentUser.isAgent">
            <router-link :to="{ name: 'agent.clients' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.clients') }}
            </router-link>
          </li>
          <li class="menu-item" v-if="currentUser && !currentUser.isAdmin">
            <router-link id="looksMenuLink"
                        :to="{ name: 'myLooks', params: { type: 'drafts' } }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.looks') }}
            </router-link>

            <ul class="menu sub-menu">
              <li class="menu-item">
                <router-link :to="{ name: 'myLooks', params: { type: 'drafts' } }">
                  {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.draftLooks') }}
                </router-link>
              </li>
              <li class="menu-item">
                <router-link :to="{ name: 'myLooks', params: { type: 'published' } }">
                  {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.publishedLooks') }}
                </router-link>
              </li>
              <li class="menu-item" v-if="currentUser && currentUser.isClient">
                <router-link :to="{ name: 'myLooks', params: { type: 'agent' } }">
                  {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.looksFromAgent') }}
                </router-link>
              </li>
            </ul>
          </li>
          <li class="menu-item"
              v-if="!currentUser || !currentUser.isAdmin">
            <router-link :to="{ name: 'studio' }">{{ $t('siteHeader.studio') }}</router-link>
          </li>
          <li class="menu-item"
              v-if="currentUser && (currentUser.isAdmin || currentUser.isMainStylist)">
            <router-link :to="{ name: 'usersPermissions' }">
              <template v-if="currentUser.isAdmin">
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.usersPermissions.admin') }}
              </template>
              <template v-else>
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.usersPermissions.mainStylist') }}
              </template>
            </router-link>
          </li>
          <li class="menu-item"
              v-if="currentUser && currentUser.isAdmin">
            <router-link :to="{ name: 'admin.stylists' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.admin.stylists') }}
            </router-link>
          </li>
          <li class="menu-item" v-if="currentUser && (currentUser.isStylist || currentUser.isAdmin)">
            <router-link :to="{ name: 'clientsOrders' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.clientsOrders') }}
            </router-link>
          </li>
          <li class="menu-item" v-if="currentUser && (currentUser.isMainStylist || currentUser.isAdmin)">
            <router-link :to="{ name: 'statistics' }">
              {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.statistics') }}
            </router-link>
          </li>
          <li class="menu-item-divider"></li>

          <template v-if="!currentUser">
            <li class="menu-item">
              <router-link :to="{ name: 'register', params: { role: 'client' } }">
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.register') }}
              </router-link>
            </li>
            <li class="menu-item">
              <router-link :to="{ name: 'login' }">
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.signIn') }}
              </router-link>
            </li>
          </template>

          <li class="menu-item with-icon" v-if="appLink">
            <a :href="appLink">
              <base-icon icon="mobile"></base-icon>&nbsp;&nbsp;{{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.downloadMobileApp') }}
            </a>
          </li>

          <template v-if="currentUser">
            <li class="menu-item">
              <router-link :to="{ name: 'profileSettings.general' }">
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.profileSettings') }}
              </router-link>
            </li>
            <li class="menu-item">
              <a href="#" @click.prevent="logout">
                {{ $t('siteHeader.siteHeaderMobile.siteHeaderMobileNavigation.signOut') }}
              </a>
            </li>
          </template>
        </ul>
      </div>
    </transition>

    <transition enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut">
        <div v-if="isOpen"
             @click="close"
             class="overlay"></div>
    </transition>
  </div>
</template>

<script>
  import { getOs } from '@/services/osDetector.js'
  import { logout } from '@/services/auth'
  import bus from '@/bus.js'

  export default {
    data() {
      return {
        isOpen: false,
        appLink: null
      }
    },

    created() {
      switch (getOs()) {
        case 'Android': this.appLink = 'https://play.google.com/store/apps/details?id=tech.escapeteam.f2c'; break;
        case 'iOS':     this.appLink = 'https://itunes.apple.com/us/app/dna2style/id1358319821'; break
        default:        this.appLink = null
      }
    },

    methods: {
      open() {
        document.body.classList.add('mobile-menu-open')
        this.isOpen = true
      },

      close() {
        document.body.classList.remove('mobile-menu-open')
        this.isOpen = false
      },

      logout() {
        logout()
        window.location.reload()
      },
    },

    watch: {
      $route(to, from) {
        this.close()
      }
    }
  }
</script>

<style lang="scss" scoped>


  .mobile-navigation {
    height: 100%;

    .mobile-navigation-trigger {
      display: flex;
      align-items: center;

      height: 100%;
      padding: 0 20px;
      cursor: pointer;
    }

    .mobile-navigation-panel {
      position: fixed;
      z-index: map-get($zIndexes, mobile-navigation);
      top: 0;
      left: 0;
      bottom: 0;
      width: 300px;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;

      .close {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }

    .menu {
      display: block;
      margin: 10px 20px 20px 20px;
      padding: 0;
      list-style: none;

      .menu-item {
        padding: 0 10px;

        & > a {
          display: flex;
          width: 100%;
          text-size-adjust: 100%;
          user-select: none;
          font-size: 16px;
          line-height: 48px;

          &.active {
            color: $primary-color;
          }
        }

        &.with-icon > a {
          align-items: center;
        }
      }

      .menu-item-divider {
        margin: 20px 0;
        border-bottom: 1px solid #eee;
      }
    }

    .sub-menu {
      margin: 0;
      padding-left: 20px;
    }

    .user-area {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #ddd;
      height: 200px;
      width: 100%;
      color: #000;
    }

    .fadeIn {
      animation: fadeIn 0.3s;
    }

    .fadeOut {
      animation: fadeOut 0.3s;
    }

    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: map-get($zIndexes, overlay);
      background-color: rgba($primary-color, .9);
    }
  }
</style>

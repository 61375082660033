<template>
  <div class="cart-section"
       ref="cartSection"
       @click="goToCart">
    <base-icon icon="cart"></base-icon>

    <span class="number-of-items"
          v-if="numberOfItems > 0">{{ numberOfItems }}</span>

    <div class="cart-dropdown" ref="cartDropdown">
      <VuePerfectScrollbar class="scrollable-area">
        <ul class="clothing-item-list">
          <li v-for="clothingItem in cartClothingItems">
            <div class="preview" :style="getStyle(clothingItem)"></div>
            <div class="info">
              <div class="title">{{ clothingItem.brand.title }} / {{ clothingItem.title }}</div>
              <div class="partner">{{ getPartnerById(clothingItem.partner_id).title }}</div>
              <div class="price">{{ clothingItem.price | currency }} {{ getCurrencyById(clothingItem.local_price_currency_id) | currencySymbol }}</div>
            </div>
          </li>
        </ul>
      </VuePerfectScrollbar>

      <base-button class="go-to-cart-btn"
                   size="small"
                   @click="goToCart">{{ $t('siteHeader.cartSection.goToCart') }}</base-button>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import tippy from 'tippy.js'

export default {
  components: {
    VuePerfectScrollbar
  },

  mounted() {
    this.initDropdown()

    this.numberOfItems > 0 ? this.enableDropdown() : this.disableDropdown()
  },

  methods: {
    initDropdown() {
      tippy(this.$refs.cartSection, {
        content: this.$refs.cartDropdown,
        interactive: true,
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        inertia: true,
        duration: [600, 300]
      })
    },

    enableDropdown() {
      this.$refs.cartSection._tippy.enable()
    },

    disableDropdown() {
      this.$refs.cartSection._tippy.disable()
    },

    goToCart() {
      this.$router.push({ name: 'cart' })
    },

    getStyle(clothingItem) {
      return {
        background: `url(${clothingItem.mainImage.middle.url}) center center / contain no-repeat`
      }
    }
  },

  computed: {
    ...mapGetters('handbooks', [
      'getPartnerById',
      'getCurrencyById'
    ]),

    ...mapGetters('cart', {
      cartClothingItems: 'getClothingItems'
    }),

    numberOfItems() {
      return this.cartClothingItems.length
    }
  },

  watch: {
    numberOfItems: {
      handler: function(value) {
        value > 0 ? this.enableDropdown() : this.disableDropdown()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-section {
  display: flex;
  align-items: center;
  height: 100%;

  .number-of-items {
    margin-left: 4px;
    font-weight: 600;
    font-size: 12px;
  }

  color: $primary-color;
  cursor: pointer;
}

.cart-dropdown {
  .scrollable-area {
    position: relative;
    max-height: 300px;
  }

  .clothing-item-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & > li {
      display: flex;
      padding: 16px 10px;
      border-bottom: 1px solid #eee;

      .preview {
        padding: 10px;
        width: 50px;
        height: 50px;
      }

      .info {
        margin-left: 10px;

        .title {
          color: $primary-color;
        }

        .partner {
          font-size: 14px;
          color: #797979;
        }

        .price {
          font-family: CormorantGaramond-SemiBold, serif;
          font-size: 22px;
          color: #282828;
          letter-spacing: 0;
          line-height: 22px;
          margin-top: 8px;
        }
      }
    }
  }

  .go-to-cart-btn {
    margin-top: 20px;
    width: 100%;
    border-radius: 0 !important;
  }
}

</style>

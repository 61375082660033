<template>
  <ValidationObserver ref="observer"
                      :disabled="disableValidationObserver"
                      slim>
    <form @submit.prevent="submit" class="form-register-agent">
      <div class="hint">{{ $t('forms.formRegisterAgent.hint') }}</div>
      <div class="form-fields">
        <base-text-box ref="fullName"
                       name="fullName"
                       v-model="formData.profile.full_name"
                       rules="required"
                       :autofocus="true"
                       :rounded="true"
                       :placeholder="$t('forms.formRegisterAgent.fullNamePlaceholder')">
        </base-text-box>
        <base-text-box ref="email"
                       name="email"
                       v-model="formData.credentials.email"
                       rules="required|email|email_unique"
                       :rounded="true"
                       :placeholder="$t('forms.formRegisterAgent.emailPlaceholder')">
        </base-text-box>
        <phone-input ref="phoneNumber"
                     name="phoneNumber"
                     v-model="formData.profile.phone_number"
                     :rules="phoneNumberValidationRules"
                     :rounded="true"
                     :placeholder="$t('forms.formRegisterAgent.phoneNumberPlaceholder')"
                     @countryChange="onCountryChange">
        </phone-input>
        <base-text-box ref="nickname"
                       name="nickname"
                       v-model="formData.profile.nick_name"
                       rules="required|nickname_unique"
                       :rounded="true"
                       :placeholder="$t('forms.formRegisterAgent.nickNamePlaceholder')">
        </base-text-box>
        <base-text-box ref="password"
                       name="password"
                       :type="showPassword ? 'text': 'password'"
                       v-model="formData.credentials.password"
                       :show-password-visibility-button="true"
                       :show-password="showPassword"
                       rules="required|min:6"
                       :rounded="true"
                       :placeholder="$t('forms.formRegisterAgent.passwordPlaceholder')"
                       @passwordVisibilityButtonClick="showPassword = !showPassword">
        </base-text-box>
      </div>
      <div class="form-group agreement">
        <base-checkbox-agreement v-model="agreed"></base-checkbox-agreement>
      </div>
      <div class="form-group">
        <base-button class="full-width"
                     type="submit"
                     :disabled="!agreed"
                     :loading="loading">
          {{ $t('forms.formRegisterAgent.createAccount') }}
        </base-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import submittable from '@/services/mixins/submittable.js'
  import { register } from '@/services/auth'
  import PhoneInput from '@/components/Shared/PhoneInput.vue'

  export default {
    mixins: [
      submittable
    ],

    components: {
      PhoneInput
    },

    data() {
      return {
        formData: {
          engine: 'email',
          credentials: {
            email: '',
            password: '',
            role: 'agent'
          },
          profile: {
            full_name: '',
            nick_name: '',
            phone_number: '',
            phone_number_country_code: null,
            locale: this.$i18n.locale
          }
        },

        showPassword: false,
        agreed: false,
        loading: false,

        phoneNumberValidationRules: {
          required: true,
          valid_phone: null,
          phonenumber_unique: null
        }
      }
    },

    methods: {
      onCountryChange(countryData) {
        this.phoneNumberValidationRules = {
          required: true,
          valid_phone: countryData.iso2,
          phonenumber_unique: countryData.iso2,
        }

        this.formData.profile.phone_number_country_code = countryData.iso2
      },

      onFormSubmit() {
        this.loading = true

        register(this.formData).then((response) => {
          this.loading = false
          this.$emit('success', {
            email:    this.formData.credentials.email,
            password: this.formData.credentials.password
          })
        }).catch((error) => {
          this.loading = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>


  .form-register-agent {
    display: flex;
    flex-direction: column;

    .hint {
      color: #797979;
      margin-bottom: 20px;
      text-align: center;
    }

    .form-fields {
      margin-bottom: 10px;
    }

    .password {
      position: relative;
      margin-bottom: 20px;

      .password-input {
        margin: 0;
      }

      .password-link {
        color: #797979;
        font-size: 14px;
        position: absolute;
        right: 20px;
        top: 15px;

        &:hover {
          color: $primary-color;
        }
      }
    }

    .agreement {
      margin-bottom: 30px;
    }

    .social-networks {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 28px;

        span {
          margin: 0 12px;
          font-size: 14px;
          color: #797979;
        }

        &:before,
        &:after {
          content: "";
          flex-grow: 1;
          height: 2px;
          border-bottom: 1px solid #797979;
          opacity: .5;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;

        button:not(:last-child) {
          margin-right: 28px;
        }
      }
    }
  }
</style>

export default {
  props: {
    fake: {
      required: false,
      type: Boolean,
      default: false
    },

    disableValidationObserver: {
      required: false,
      type: Boolean,
      default: true
    },

    showSubmitButton: {
      required: false,
      type: Boolean,
      default: true
    },

    showCancelButton: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      submitting: false
    }
  },

  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        this.$emit('submitFail')
        return false
      }

      this.$emit('submitStart')

      this.submitting = true
      const result = await this.onFormSubmit()
      this.submitting = false

      this.$emit('submitSuccess', result)
    }
  }
}
<template>
  <div class="site-header-mobile">
    <div class="left">
      <mobile-navigation class="mobile-navigation"></mobile-navigation>
    </div>
    <div class="logo">
      DNA2STYLE
    </div>
    <div class="right">
      <mobile-language style="margin-right: 20px;"></mobile-language>
      <mobile-cart class="cart" v-if="currentUser && currentUser.isAgent"></mobile-cart>
    </div>
  </div>
</template>

<script>
  import MobileNavigation from './SiteHeaderMobileNavigation.vue'
  import MobileCart from './SiteHeaderMobileCart.vue'
  import MobileLanguage from './SiteHeaderMobileLanguage.vue'
  import bus from '@/bus'

  export default {
    components: {
      MobileNavigation,
      MobileLanguage,
      MobileCart
    },

    created() {
      bus.$on('loginRequested', this.login)
      bus.$on('registerRequested', (userType = null) => this.register(userType))
    },

    methods: {
      login() {
        this.$router.push({ name: 'login' })
      },

      register(userType) {
        let registerRoute = {
          name: 'register',
          params: {}
        }

        if (userType) {
          registerRoute.params.role = userType
        }

        this.$router.push(registerRoute)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .site-header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    left: 0;
    top: 0;
    z-index: map-get($zIndexes, header);
    height: $header-height;
    width: 100vw;

    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(235,235,235,0.50);

   .logo {
      color: $primary-color;
      font-weight: bold;
    }

    .right {
      display: flex;
      padding-right: 20px;
    }
  }
</style>

import getLocale from '@/services/locale'
import profileService from '@/services/queries/profileQueries.js'

export default {
  mounted() {
    this.initLanguageDropdown()
  },

  methods: {
    setLocale(locale) {
      if (!this.authenticated) {
        this.changeRouteLocale(locale)

        return
      }

      profileService.update(this.$currentUser.user_id, { locale }).then(response => {
        this.changeRouteLocale(response.locale)
      })
    },

    changeRouteLocale(locale) {
      this.$router.push({
        query: {
          ...this.$route.query,
          locale
        }
      })

      window.location.reload()
    },

    initLanguageDropdown() {
      tippy(this.$refs.currentLocale, {
        content: this.$refs.languageDropdown,
        interactive: true,
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        inertia: true,
        duration: [600, 300]
      })
    }
  },

  computed: {
    ...mapGetters('auth', [
      'authenticated'
    ]),

    currentLocaleFlag() {
      switch (getLocale()) {
        case 'ru_RU': return '/pictures/flags/russia.png'
        case 'en_US': return '/pictures/flags/usa.png'
      }
    },

    currentLocaleTitle() {
      switch (getLocale()) {
        case 'ru_RU': return 'Русский'
        case 'en_US': return 'English'
      }
    }
  }
}

<template>
  <header class="site-header">
    <a href="/" class="logo">
      <img src="/logo.svg">
    </a>
    <div class="links">
      <router-link class="top-link"
                   :to="{ name: 'catalogue', params: { gender: 'female' } }">
        {{ $t('siteHeader.catalogue') }}
      </router-link>

      <router-link class="top-link"
                   :to="myShopRoute"
                   v-if="userTypeDefined">
        {{ $t('siteHeader.myShop') }}
      </router-link>
      <div class="top-link"
           @click="openTypeDefinitionModal"
           v-else>
        {{ $t('siteHeader.myShop') }}
      </div>

      <router-link class="top-link"
                   :to="{ name: 'myOutfits' }">
        {{ $t('siteHeader.myOutfits') }}
      </router-link>

      <router-link class="top-link"
                   v-if="$currentUser && ($currentUser.isAdmin || $currentUser.isMainStylist)"
                   :to="{ name: 'usersPermissions' }">
        {{ $currentUser.isAdmin ? $t('siteHeader.usersPermissions.admin') : $t('siteHeader.usersPermissions.mainStylist') }}
      </router-link>

      <router-link class="top-link"
                   v-if="$currentUser && $currentUser.isAdmin"
                   :to="{ name: 'admin.stylists' }">{{ $t('siteHeader.admin.stylists') }}</router-link>

      <router-link class="top-link"
                   v-if="$currentUser && ($currentUser.isStylist || $currentUser.isAdmin)"
                   :to="{ name: 'clientsOrders' }">{{ $t('siteHeader.clientsOrders') }}</router-link>

      <router-link class="top-link"
                   v-if="$currentUser && ($currentUser.isStylist && $currentUser.capabilities.is_statistic_allowed ||
                  $currentUser.isAdmin)"
                   :to="{ name: 'statistics' }">{{ $t('siteHeader.statistics') }}</router-link>
    </div>

    <div class="user">
      <div class="motto">
        Your AI shopping assistant
      </div>
      <cart-section v-if="$currentUser" />
    </div>

    <portal to="modals">
      <auth-modal :show="authModalSettings.open"
                  :type="authModalSettings.content"
                  :user="authModalSettings.user"
                  @close="closeAuthModal" />
    </portal>
  </header>
</template>

<script>
import LanguageSection from './LanguageSection/LanguageSection.vue'
import CartSection from './CartSection/CartSection.vue'
import UserSection from './UserSection/UserSection.vue'
import AuthModal from '@/components/Auth/AuthModal/AuthModal.vue'
import bus from '@/bus'

export default {
  components: {
    LanguageSection,
    CartSection,
    UserSection,
    AuthModal
  },

  created () {
    bus.$on('loginRequested', this.login)
    bus.$on('registerRequested', this.register)
  },

  methods: {
    openTypeDefinitionModal () {
      this.$modal.show('typeDefinition')
    },

    login () {
      this.openAuthModal({ content: 'login' })
    },

    register () {
      this.openAuthModal({ content: 'register' })
    },

    ...mapActions('system', [
      'openAuthModal',
      'closeAuthModal'
    ])
  },

  computed: {
    userTypeDefined () {
      return this.$currentUser.figure_type_id || this.$currentUser.color_type_id
    },

    myShopRoute () {
      return {
        name: 'myShop',
        params: {
          gender: 'female'
        },

        query: {
          figure_type_id: this.$currentUser.figure_type_id,
          color_type_id: this.$currentUser.color_type_id
        }
      }
    },

    ...mapGetters('system', {
      authModalSettings: 'getAuthModalSettings'
    })
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  display: flex;
  align-items: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: map-get($zIndexes, header);
  height: $header-height;
  width: 100%;

  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(235,235,235,0.50);

  .logo {
    flex-basis: 96px;
    flex-shrink: 0;
    margin: 0 42px;

    img {
      width: 100%;
    }
  }

  .links {
    display: flex;
    list-style-type: none;
    height: 100%;

    .top-link {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 28px;

      color: #727272;
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;

      &:hover, &.active {
        &::after {
          content:'';
          position: absolute;

          bottom: 0;
          height: 1px;
          width: 100%;

          background: $primary-color;
        }
      }
    }
  }

  .description {
    display: flex;
    align-items: center;

    height: 100%;
    margin: 0 24px;
  }

  .sub-links {
    display: flex;

    .sub-links-popover {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    .sub-link {
      color: #727272;
      text-decoration: none;
      font-size: 16px;
      padding: 10px;

      &:hover, &.active {
        color: $primary-color;
      }
    }
  }

  .user {
    height: 100%;

    margin-left: auto;
    margin-right: 42px;

    display: flex;
    align-items: center;
  }

  .motto {
    margin-right: 20px;
  }
}
</style>

<template>
  <div class="body-types">
    <div class="title">
      Body type
    </div>

    <div class="types">
      <div class="type"
           v-for="type in types"
           :key="type.name"
           :class="{ 'active': type.active }"
           @click="select(type.id)">
        <svg width="150">
          <use :xlink:href="`#${type.image}`"/>
        </svg>
        <div class="title">
          {{ type.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      types: [
        { name : 'Hourglass', image: 'hourglassBody', active: false, id: 4},
        { name : 'Inverted triangle', image: 'invertedTriangleBody', active: false, id: 2},
        { name : 'Rectangle', image: 'rectangleBody', active: false, id: 3},
        { name : 'Triangle', image: 'triangleBody', active: false, id: 1}
      ]
    }
  },

  mounted () {
    this.select(2)
  },

  methods: {
    select (id) {
      this.deselectAll()

      let selectedType = this.types.find(type => type.id === id)
      this.$set(selectedType, 'active', true)

      this.$emit('select', selectedType.id)
    },

    deselectAll () {
      this.types = this.types.map(type => ({
        ...type,
        active: false
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
  font-size: 16px;
  color: rgb(208, 208, 208);
  text-transform: uppercase;
}

.types {
  display: flex;

  .type {
    width: 150px;
    height: 250px;
    border: 1px solid rgb(233, 233, 233);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s ease 0s;

    bottom: 1px;
    background: white;

    &:hover {
      background-color: rgb(233, 233, 233);
    }

    &:active {
      background-color: rgb(221, 221, 221);
      border-color: rgb(221, 221, 221);
    }

    &.active {
      background-color: rgb(233, 233, 233);
      cursor: default;
      pointer-events: none;
    }

    .image {
      display: flex;
      justify-content: center;
      height: 180px;
      width: 100%;
    }

    .title {
      text-transform: none;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: rgb(27, 33, 36);
    }
  }
}
</style>
